<template>
  <a-modal
    :visible="showSetupTrainingModal"
    title="Setup Training"
    :mask-closable="false"
    @cancel="resetSetupTrainingModal"
    width="45vw"
    :bodyStyle="{ overflowY: 'auto' }"
    :destroyOnClose="true"
  >
    <template #footer>
      <a-button
        type="primary"
        @click="handleSetupTraining"
        :loading="requestTrainingLoading"
        >Request Training</a-button
      >
    </template>
    <a-tabs v-model:activeKey="activeKey">
      <!-- Setup Training Tab -->
      <a-tab-pane key="1" tab="Model Configuration" forceRender>
        <a-card title="Yolo Version" size="small" hoverable>
          <a-select
            class="w-100"
            v-model:value="selectedYoloVersion"
            :options="yoloVersionOptions"
          />
        </a-card>
        <a-card title="Yolo Variant" size="small" hoverable class="mt-1">
          <a-select
            class="w-100"
            v-model:value="selectedYoloVariant"
            :options="yoloVariantOptions"
          />
        </a-card>
        <a-card title="Description" size="small" hoverable class="mt-1">
          <a-textarea v-model:value="trainingDescription" />
        </a-card>
      </a-tab-pane>

      <!-- Training Parameters Tab-->
      <a-tab-pane
        key="2"
        tab="Training Configuration"
        style="height: 60vh; overflow-y: auto"
      >
        <a-card title="Training Parameters" size="small" hoverable>
          <a-card title="Batch Size" size="small" hoverable class="mt-1">
            <a-input-number
              class="w-100"
              v-model:value="trainingParams.batchSize"
              :min="0"
            />
          </a-card>
          <a-card title="Patience" size="small" hoverable class="mt-1">
            <a-input-number
              class="w-100"
              v-model:value="trainingParams.patience"
              :min="0"
            />
          </a-card>
          <a-card title="Epochs" size="small" hoverable class="mt-1">
            <a-input-number
              class="w-100"
              v-model:value="trainingParams.epochs"
              :min="0"
            />
          </a-card>
        </a-card>

        <a-card title="Split Size" size="small" hoverable class="mt-3">
          <!-- <a-card title="Train" size="small" hoverable class="mt-1">
            <a-input-number
              class="w-100"
              v-model:value="trainingParams.train"
              :min="0"
              :max="100"
              :step="10"
            />
          </a-card> -->
          <a-card title="Validation Split (%)" size="small" hoverable class="mt-1">
            <a-input-number
              class="w-100"
              v-model:value="trainingParams.validation"
              :min="0"
              :max="100"
              :step="10"
            />
          </a-card>
        </a-card>
        <!--
        <a-card
          v-if="taskObjectsName.length !== 0"
          title="Objects Split"
          size="small"
          hoverable
          class="mt-3"
          :bodyStyle="{ overflowY: 'auto' }"
        >
          <a-form
            layout="horizontal"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 14 }"
            :labelAlign="'right'"
            :colon="false"
            v-for="obj in taskObjectsName"
          >
            <a-form-item :label="obj">
              <a-input-number
                class="w-100"
                v-model:value="trainingParams.objects[obj]"
                :min="0"
                :max="100"
                :step="5"
              />
            </a-form-item>
          </a-form>
        </a-card>
        -->
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  inject: ['toast'],
  emits: ['performTraining', 'setShowSetupTrainingModal'],
  props: ['showSetupTrainingModal'],
  data() {
    return {
      activeKey: '1',
      selectedYoloVersion: null,
      selectedYoloVariant: null,
      trainingDescription: null,
      requestTrainingLoading: false,
      yoloVersionOptions: [
        { label: 'Yolov5', value: 'yolov5' },
        { label: 'Yolov8', value: 'yolov8' },
      ],
      yoloVersionFive: ['nu', 'su', 'mu', 'lu', 'xu'],
      yoloVersionEight: ['n', 's', 'm', 'l', 'x'],
      trainingParams: {
        batchSize: 32,
        patience: 20,
        epochs: 100,
        validation: 20,
      },
    };
  },

  computed: {
    ...mapGetters(['taskObjects']),

    taskObjectsName() {
      if (!this.taskObjects) return [];
      let tasks = [];
      this.taskObjects.forEach((object) => {
        if (object.name) tasks.push(object.name);
      });
      this.trainingParams.objects = {};
      tasks.forEach((value) => (this.trainingParams.objects[value] = 20));
      return tasks;
    },

    isSetupTrianingDisabled() {
      return !(
        this.selectedYoloVersion &&
        this.selectedYoloVariant &&
        this.trainingDescription
      );
    },

    isTrainingParamsDisabled() {
      return !(
        this.trainingParams.batchSize &&
        this.trainingParams.patience &&
        this.trainingParams.epochs
      );
    },

    yoloVariantOptions() {
      if (!this.selectedYoloVersion) return [];
      if (this.selectedYoloVersion.toLowerCase() === 'yolov5') {
        return this.yoloVersionFive.map((version) => {
          return { label: version, value: version };
        });
      }

      if (this.selectedYoloVersion.toLowerCase() === 'yolov8') {
        return this.yoloVersionEight.map((version) => {
          return { label: version, value: version };
        });
      }
    },
  },

  methods: {
    resetSetupTrainingModal() {
      this.requestTrainingLoading = false;
      this.selectedYoloVersion = null;
      this.selectedYoloVariant = null;
      this.trainingDescription = null;
      this.trainingParams.batchSize = null;
      this.trainingParams.epochs = null;
      this.trainingParams.patience = null;
      this.trainingParams.validation = null;
      this.closeModal();
    },

    closeModal() {
      this.$emit('setShowSetupTrainingModal', false);
    },

    async handleSetupTraining() {
      this.requestTrainingLoading = true;
      if (this.isSetupTrianingDisabled) {
        this.requestTrainingLoading = false;
        return this.toast.error(
          'Please configure parameters of Model Configuration.'
        );
      }
      // if (this.trainingParams.train + this.trainingParams.validation !== 100) {
      //   this.requestTrainingLoading = false;
      //   return this.toast.error(
      //     'Please configure Training Split percentages add up to 100%.'
      //   );
      // }
      const modelData = {
        yolo_version: this.selectedYoloVersion,
        yolo_variant: this.selectedYoloVariant,
        model_version_description: this.trainingDescription,
        batch_size: this.trainingParams.batchSize,
        patience: this.trainingParams.patience,
        epochs: this.trainingParams.epochs,
        validation: this.trainingParams.validation / 100,
      };
      this.$emit('performTraining', modelData);
      this.resetSetupTrainingModal();
    },
  },
};
</script>
