<template>
  <train-model></train-model>
</template>

<script>
import TrainModel from './TrainModel.vue';

export default {
  components: {
    TrainModel,
  },
};
</script>
